import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AlartContentColumn from './AlartContentColumn';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';


function ContextAwareToggle({ children, eventKey, callback, lang }) {
  const { activeEventKey } = useContext(AccordionContext);
  const { t } = useTranslation();

  const changeOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button className="see-more-see-less" style={{float: lang !== "ar" ? 'right' : "left"}}
      type="button"
      
      onClick={changeOnClick}
    >
      {isCurrentEventKey ? t('see_less') : t('see_more')}
    </button>
  );
}




function AlartTabs(props) {

  const { t } = useTranslation();
  const [intro, generateIntro] = useState("");

  useEffect(() => {
    props.hasIntro !== undefined &&  
    props.hasIntro.summary !== undefined && 
    props.hasIntro.summary.length > 0 &&
  generateIntro(props.hasIntro.summary.slice(1));

}, [props.lang, props.tabs, props.hasIntro, props.argument]);


  return (
    <div style={{marginTop:"20px"}}>
  { props.hasIntro !== undefined  && props.hasIntro.issue_no_level1 !== undefined  && 
<div>
    <h5 style={{marginTop:"20px"}}>{props.hasIntro.issue_no_level1}. {props.hasIntro.issue_en_level1}</h5>
    <div className="alartContent">
      
      
    {Array.isArray(props.hasIntro.summary) && props.hasIntro.summary.length === 1  && props.hasIntro.summary.map((sum , num) => 
      <p className="intro-paragraph" key={num}>{sum}</p>
       )}
 <Row>
       {Array.isArray(props.hasIntro.summary) && props.hasIntro.summary.length > 1  &&
        <Accordion defaultActiveKey="">
      <Accordion.Item   style={{border: "none"}} className={props.lang === "ar" ? "accordion-intro no-arrow" : "accordion-intro no-arrow"} eventKey="281">
      <Accordion.Header className="intro tab-intro">{props.hasIntro.summary[0]}<br/></Accordion.Header>
      <Accordion.Body>{intro !== undefined && intro.length > 0 && intro.map((para, key) => <p key={key}>{para}</p>)} </Accordion.Body></Accordion.Item>
      <ContextAwareToggle lang={props.lang} eventKey="281"></ContextAwareToggle>
      </Accordion>}
      </Row>
      
      
      
      </div> 
    </div>}  

         
         {props.hasTabsIntro !== undefined && 
         
          <div>{ props.hasTabsIntro.summary !== undefined && 
       
        
                <Row style={{backgroundColor: "#E5ECF4"}}>
              <h5 style={{marginTop:"20px"}}>{props.hasTabsIntro.issue_no_level2} {t(props.argument)}</h5>
              <br/>
             { props.hasTabsIntro.summary.map(( paragh, key )=>
                <p style={{fontWeight: "200"}}key={key}>{paragh}</p>)} 
                </Row>}</div> }


   <Tabs style={{marginTop:"20px"}}
      defaultActiveKey="0"
      className="mb-3 alart-tabs"
   justify
    >
        {props.issues.sort((a, b) => a.issue_ID > b.issue_ID ? 1 : -1).map((tabIssue, key) =>  
        <Tab key={key} eventKey={key} title={tabIssue.issue_en_level3}>
        <AlartContentColumn lang={props.lang}  argument={props.tabs[key]} issue={tabIssue} isTab={true} /> 
        </Tab>)}
    </Tabs>
    </div>



  );
}

export default AlartTabs;