import React  from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/js/bootstrap.js'
import { BrowserRouter } from "react-router-dom";
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);
