import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';





function ContextAwareToggle({ children, eventKey, callback, lang }) {
  const { activeEventKey } = useContext(AccordionContext);
  const { t } = useTranslation();

  const changeOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button className="see-more-see-less" style={{float: lang !== "ar" ? 'right' : "left"}}
      type="button"
      
      onClick={changeOnClick}
    >
      {isCurrentEventKey ? t('see_less') : t('see_more')}
    </button>
  );
}


function AlartContentColumn(props) {

  const { t } = useTranslation();
  const [intro, generateIntro] = useState("");


  useEffect(() => {

   props.argument !== "institutional_arrangements" && 
    props.argument !== "policy_issues" && 
    props.isTab === false &&
    props.hasIntro !== undefined && 
    props.hasIntro.summary && 
    props.hasIntro.summary.length > 0 &&
    generateIntro(props.hasIntro.summary.slice(1)); 



    (props.argument === "institutional_arrangements" ||
    props.argument === "policy_issues") && 
    props.isTab === false &&
    props.issue.summary !== undefined && 
    props.issue.summary.length > 0 &&
    generateIntro(props.issue.summary.slice(1));

 
}, [props.hasIntro, props.argument, props.issue, props.isTab]);

  return (
    <React.Fragment>
  

      { props.argument === "policy_issues" && props.issue !== undefined && 
     <div key={props.issue.issue_no_level1 + "polIssues"}>
     <h5 style={{marginTop:"20px"}}>{props.issue.issue_no_level1}. {props.issue.issue_en_level1}</h5> 
    <div key={props.issue.issue_no_level1 + "polIssues"} className="alartContent">
    {Array.isArray(props.issue.summary) && props.issue.summary.length === 1  && props.issue.summary.map((sum , num) => 
      <p className="intro-paragraph" key={num}>{sum}</p>
       )}

       {Array.isArray(props.issue.summary) && props.issue.summary.length > 1  &&
        <Accordion defaultActiveKey="0">
      <Accordion.Item   style={{border: "none"}} className={props.lang === "ar" ? "accordion-intro no-arrow" : "accordion-intro no-arrow"} eventKey="281">
      <Accordion.Header className="intro" style={{margin: "0px"}}>{props.issue.summary[0]}<br/></Accordion.Header>
      <Accordion.Body >{intro !== undefined && intro.length > 0 && intro.map((para, key) => <p key={key}>{para}</p>)}</Accordion.Body></Accordion.Item>
      <ContextAwareToggle lang={props.lang} eventKey="281"></ContextAwareToggle>
      </Accordion>}
       </div> 
    </div>}  
{ props.argument === "institutional_arrangements" && props.issue !== undefined && 
<div /* key="IssueLevel1 */ >
     <h5 style={{marginTop:"20px"}}>{props.issue.issue_no_level1}. {props.issue.issue_en_level1}</h5>
    <div key="IssueLevel1" className="alartContent">
    {Array.isArray(props.issue.summary) && props.issue.summary.length === 1  && props.issue.summary.map((sum , num) => 
      <p className="intro-paragraph" key={num}>{sum}</p>
       )}

        {Array.isArray(props.issue.summary) && props.issue.summary.length > 1  &&
        <Accordion defaultActiveKey="0">
      <Accordion.Item style={{border: "none"}} className={props.lang === "ar" ? "accordion-intro no-arrow" : "accordion-intro no-arrow"} eventKey="281">
      <Accordion.Header className="intro" ><Row>{props.issue.summary[0]}</Row><br/></Accordion.Header>
      <Accordion.Body >{intro !== undefined && intro.length > 0 && intro.map((para, key) => <p key={key}>{para}</p>)}</Accordion.Body></Accordion.Item>
      <ContextAwareToggle lang={props.lang} eventKey="281"></ContextAwareToggle>
      </Accordion>}
    
    </div> 
    </div>}    


   { props.hasIntro !== undefined  && props.hasIntro.issue_no_level1 !== undefined &&
<div key="IntroIssueLevel1">
    <h5 style={{marginTop:"20px"}}>{props.hasIntro.issue_no_level1}. {props.hasIntro.issue_en_level1}</h5>
    <div key="IntroIssueLevel1" /* className="alartContent" */>
    {Array.isArray(props.hasIntro.summary) && props.hasIntro.summary.length === 1  && props.hasIntro.summary.map((sum , num) => 
      <p className="intro-paragraph" key={num}>{sum}</p>
       )}

        {Array.isArray(props.hasIntro.summary) && props.hasIntro.summary.length > 1  &&
        <Accordion defaultActiveKey="0">
      <Accordion.Item style={{border: "none"}} className={props.lang === "ar" ? "accordion-intro no-arrow" : "accordion-intro no-arrow"} eventKey="281">
      <Accordion.Header className="intro" style={{margin: "0px"}}>{props.hasIntro.summary[0]}<br/></Accordion.Header>
      <Accordion.Body >{intro !== undefined && intro.length > 0 && intro.map((para, key) => <p key={key}>{para}</p>)}</Accordion.Body></Accordion.Item>
      <ContextAwareToggle  t={{t}} lang={props.lang} eventKey="281"></ContextAwareToggle>
      </Accordion>}
    
    
    
    </div> 
    </div>}   
        <Accordion defaultActiveKey="0">
        {props.argument !== "policy_issues" && props.argument !== "institutional_arrangements" &&  <h4>{t(props.summary)}</h4>} 
          {props.argument !== "policy_issues" && props.argument !== "institutional_arrangements" && props.issue !== undefined && 
          <div key="IntroArgument">{ props.issue.summary !== undefined && 
       
          <Accordion.Item   style={{border: "none"}}  className={props.lang === "ar" ? "arabic-fix no-arrow no-border intro" : "no-arrow no-border intro"} eventKey="110">
              <Accordion.Header className="intro">
                <Row >
              <h5 style={{marginTop:"20px"}}>{props.issue.issue_no_level2} {t(props.argument)}</h5>
              <br/>
             { props.issue.summary.map(( paragh, key )=>
                <p key={key + "summary"} style={{fontWeight: "200", lineHeight: "var(--bs-body-line-height"
              }}>{paragh}</p>)} 
                </Row></Accordion.Header>
               
                </Accordion.Item>}</div> } 

                
            <h4 style={{marginTop:"1em"}}>{t('questions')}</h4>
          {props.issue !== undefined && props.issue.questions !== undefined && <div style={{marginTop: "2rem"}}>{props.issue.questions.map( 
          (item, number) =>
          <div key={number + "questions"} >
          <Accordion.Item key={number}  className={props.lang === "ar" ? "arabic-fix accordion-AlartContent" : "accordion-AlartContent"} eventKey={item.question_no}>
           <Accordion.Header className="accordion-AlartContent"><h6>{item.question_no}. {item.question_en}</h6> </Accordion.Header>
            <Accordion.Body className="accordion-AlartContent"> 
           <Row style={{border: "none", borderRadius: "0px!important"}} >
            {  item.question_if !== undefined && <div className="alartContent"><h6 style={{fontWeight: "600"}}>{t('answer').toUpperCase()}</h6>{item.question_if.map((ifQuest, index) =>
             <p key={"ifQuest" + index} style={{marginBottom: "0px"}}>{ifQuest}</p>)}<br/></div> }
      

           { item.explanations !== undefined && Array.isArray(item.explanations) && 
             item.explanations.map((element, id) => 
             <div key={id + "explan"}>{element !== undefined && 
             <div key={id + "explanations"} style={{borderBottom: id !== item.explanations.length -1 ? "1.5px solid black" : "none", marginBottom: "2rem"}}>
               <h6 style={{fontWeight: "600"}} key={id + "explanationsParagraph"} >{t("explanation").toUpperCase()}</h6>
               {element.explanation !== undefined && <p className="alart-examples-data">{element.explanation}</p>}
              <h6 key="examples" style={{fontWeight: "600"}} className="alart-subtitle">{element.examples!== undefined && Array.isArray(element.examples) &&  t("examples").toUpperCase()}</h6>
              
              {(element.examples!== undefined && Array.isArray(element.examples) &&  element.examples.map((exa, id) => 
             <div key={id + "exa"} style={{paddingBottom: "1.5rem", marginBottom: "1.5rem", borderBottom: id < element.examples.length - 1 ? "3px solid #F2F2F2" : ""}} >
             
              <span className="alart-examples-country">{exa.country}:</span> <span className="alart-text-title"> 
              <a target="_blank" rel="noreferrer" href={exa.url_pdf}>{exa.title_of_text}</a></span><br/>
              {exa.date_of_text !== undefined && <span className="alart-examples-data">{t("date_of_text")}: {exa.date_of_text}</span>}
              {exa.date_of_original_text !== undefined && <span className="alart-examples-data">{t("date_of_original_text")}: {exa.date_of_original_text}</span>}
              {exa.last_amended_date !== undefined && <span className="alart-examples-data"> {t("last_amended_date")}: {exa.last_amended_date}</span>} 
              
              
              
              <br/>
              <span className="alart-examples-data">{t("faolex_number")}</span><span className="alart-text-title"><a style={{fontSize: "small"}} target="_blank" rel="noreferrer" href={exa.url_faolex}> {exa.id}</a></span><br/>
              {exa.measure !== undefined && <p id="exa-measure" style={{marginTop: "20px", marginBottom: "0rem", textDecoration: "underline"}} className="alart-examples-data">{exa.measure}</p>}
              <p key="exa-comment" style={{ marginBottom: "0px"}} className="alart-examples-data">{exa.comments}</p>
              {exa.source !== undefined && <span  id={exa.id}className="alart-examples-data">{t("source")}: {exa.source}<br/></span>}
             </div>))}
             {element.related_international_instruments !== undefined && <p key="rel-instruments" className="alart-examples-data">{t("related_international_instruments")}: {element.related_international_instruments.map(rel => <span key={rel.name_en}>{rel.name_en}</span>)}</p>}
             
             </div>}</div>)} 
        </Row> 
         </Accordion.Body> 
          </Accordion.Item> 
          <Row style={{marginBottom:"3%", backgroundColor: "white", height: "20px"}}></Row>
          </div>
        )}</div>}  
         </Accordion> 
    </React.Fragment>



  );
}

export default AlartContentColumn;




