import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import logos from '../../styles/logos.json';





const normFraData = (data) => {
    console.log(data);

    if(data.documents !== undefined && Array.isArray(data.documents)) {
        return data.documents.map(item => {
            return ([
                {text: item.country !== undefined ? item.country : item.regional_organization.concat(item.territorial_subdivision !== undefined ? " (" + item.territorial_subdivision + ")" : ""), fontSize: 10},
                {text: item.title_of_text,  link: item.url_pdf, style: "link"},
                {text: item.year_of_text.concat(item.last_amended_year !== undefined ? " (" + item.last_amended_year  + ")": ""), fontSize: 10},
                {text: item.id,  link: item.url_faolex, style: "link"},
            ]);
        });
       
}
}


const intFraData = (data) => {
    console.log(data);
    if(data.documents !== undefined && Array.isArray(data.documents)) {
        return data.documents.map(item => {
            return (
                [
                [{text:item.name_en, link: item.url, color: '#116AAB', decoration: "underline",fontSize: 12}],
                [{text: item.why_relevant_to_alart, fontSize: 10, alignment: 'justify'}]]);
        });
       
}  
}





function NormativeFrameworksPDFBuilder (tableData, titles, pageTitle, type, norFraTitle, norFraSummary, language) {

    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    const today = new Date().toLocaleString();


const formattedData = type === "national" ? normFraData(tableData) : intFraData(tableData);
  const documentDefinition  = {
		pageSize: 'A4',
		pageOrientation: 'portrait',
        footer: function(currentPage, pageCount) { return [{text: currentPage.toString() + ' of ' + pageCount, alignment: "center" },{text:today.toLocaleString().substring(0, 10), alignment: "right", margin: [10,10,10,20]}]},
    
		content: [
             {
                image: logos[language.concat('_logo')],   
               width: 200,
        
          },   
                '\n',
            {text: norFraTitle,  fontSize: 15, bold: true},
            '\n',
			{text: pageTitle,  fontSize: 14, bold: true},
			'\n',
            {text:  norFraSummary,  fontSize: 10, alignment: 'justify'},
			'\n',
          
            {
				table: type === "national" ?
                {
					headerRows: 1,
					dontBreakRows: true,
                    widths: [ '*', 'auto', 100, '*' ],

					body: [
					
                        [{text: titles[0], fontSize: 12, bold: true}, {text: titles[1], fontSize: 12, bold: true}, {text: titles[2]+"\n(" + titles[3] + ")", fontSize: 12, bold: true}, {text: titles[4], fontSize: 12,bold: true, }],
                          ...formattedData,
					] 
				} :                
                {
					//headerRows: 1,
					dontBreakRows: true,

					body: [
                        ...formattedData,
                        
					] 
				}
			}
		],
        styles: {
              link: {
               fontSize: 10,
               color: '#116AAB',
               decoration: "underline",
               bold: true,
              }
          }
  };

	pdfMake.createPdf(documentDefinition).download( tableData.name + " - ALART " + norFraTitle);
}

export default NormativeFrameworksPDFBuilder;