import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';


function NormativeFrameworksContentColumn(props) {
  
  const { t } = useTranslation();

  useEffect(() => {
   
   
    //console.log( props.data !== undefined && props.data.documents.length);
    //console.log(props.argument);
   
}, [props.lang, props.argument, props.data]);



    return (
      <React.Fragment>
           {props.argument === "Treaties" ?
        <div >
        <h6 className="treaties-page-title">{t("title_treaties")}</h6>
          { props.data !== undefined && props.data.documents !== undefined && props.data.documents.map( treaty =>
  <Table className="treaties-table" key={treaty.id} bordered responsive hover size="sm">
  <tbody>
  <tr>
       {/* <td></td> */}
      <td ><a target="_blank" rel="noreferrer" href={treaty.url}  style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"#116AAB", paddingLeft:"8px", paddingRight:"8px"}}>{treaty.name_en}</span> </a></td>
     
    </tr>
    <tr>
    {/*   <td style={{minWidth:"12rem"}} >{t('why_relevant_to_alart')}</td> */}
      <td >{treaty.why_relevant_to_alart}</td>
    
    </tr>
   

 
  </tbody>
</Table> 

            )}  
    </div> :
       props.argument === "Soft law instruments and guidelines" || props.argument === "Aquaculture certification schemes"   ?
    <div>
      <div className="argument-intro">

        {props.argument === "Soft law instruments and guidelines" ?
        <div>
    <h5>{t("soft_law")}</h5>
    <p key="IntroIssueLevel1" className="alart_first_paragraph">{t('soft_law_int_normative_frameworks_intro')}</p> </div> : 

<div>
<h5>{t("aquaculture_certification")}</h5>
<p key="IntroIssueLevel1" className="alart_first_paragraph">{t('aquaculture_int_normative_frameworks_intro')}</p> </div>}

    
    </div>
    { props.data !== undefined && props.data.documents !== undefined && props.data.documents.map( doc =>
  <Table key={doc.id} bordered responsive hover size="sm">
  <tbody>
  <tr>

      <td ><a target="_blank" rel="noreferrer" href={doc.url}  style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"#116AAB", paddingLeft:"8px", paddingRight:"8px"}}>{doc.name_en}</span> </a></td>
     
    </tr>
    <tr>
      <td>{doc.why_relevant_to_alart !== undefined && doc.why_relevant_to_alart.map((para, index) => <p key={"treatyParagraph" + index}>{para}</p>)}</td>
    
    </tr>
   

 
  </tbody>
</Table> 

            )} 
    </div>  : <div><h6 style={{marginBottom: "2rem", marginTop: "2rem", fontSize: "large"}}>{t(props.pageTitle)}</h6>
    <Table  bordered responsive hover size="sm" >
    <thead>
    <tr>
    <th>{t('jurisdiction')}</th>
          <th>{t('title_text')}</th>
          <th style={{width: "12rem"}}>{t('year_of_text')}<br/>{ " (" + t('year_amended_date') + ")"}</th>
          <th style={{width: "12rem"}}>{t('faolex_number')}</th>
        </tr>
      </thead>
 <tbody className="national-table">
{props.data !== undefined && props.data.documents !== undefined && props.data.documents.map( national =>


   <tr key={national.id}>
     <td>{national.country}{national.regional_organization}{national.territorial_subdivision !== undefined && " (" + national.territorial_subdivision + ")"}</td>
     
    
      <td ><a target="_blank" rel="noreferrer" href={national.url_pdf}  style={{textDecoration: 'none'}}>
        <span style={{color:"#116AAB", paddingLeft:"0px", paddingRight:"0px"}}>{national.title_of_text}</span> </a></td>
      <td>{national.year_of_text}{national.last_amended_year !== undefined && " (" + national.last_amended_year + ")"}</td>
      <td><a target="_blank" rel="noreferrer" href={national.url_faolex}  style={{textDecoration: 'none', color: "#116AAB"}}>{national.id}</a></td>
   </tr>
  


 )}
  </tbody>
</Table>
{props.data !== undefined && props.data.see_more_url !== undefined  && <div>
  <p>{t('part_one_see_more') + " "}<a target="_blank" rel="noreferrer" 
  href={props.data.see_more_url}  style={{textDecoration: 'none', color: "#116AAB"}}>{t('advanced_search')}</a>{" " + t('part_two_see_more') + " "}</p></div>}



  </div>}
      </React.Fragment>
    );
  }

  export default NormativeFrameworksContentColumn;