import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import CloseButton from 'react-bootstrap/CloseButton';

import AlartContentColumn from '../components/Alart/AlartContentColumn';
import AlartSideBar from '../components/Alart/AlartSideBar';
import AlartTabs from '../components/Alart/AlartTabs';

import AlartToolPDFBuilder from  '../components/Alart/pdfGenerateAlartTool';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useTranslation } from 'react-i18next';



import '../styles/AlartContentColumn.css'; 
import '../styles/AlartSideBar.css'; 


pdfMake.vfs = pdfFonts.pdfMake.vfs;
function exportPdfTable(argument, mainIssue, IntroIssue, titles, tabIntro, language) {

  // change this number to generate more or less rows of data
  AlartToolPDFBuilder(argument, mainIssue, IntroIssue, titles, tabIntro, language);
}



function AlartPage(props) {
 
    const [open, setOpen] = useState(true);
    const [issues, setIssues] = useState("");
      const[jsonLoaded, setLoad] = useState(false);
      const { t } = useTranslation();


    useEffect(() => {
      const url = "https://faolex.fao.org/docs/json/alart.json";
  
      const fetchData = async () => {
        if(jsonLoaded === false) {
          try {
              const response = await fetch(url);
              const json = await response.json();
           
                setIssues(json);
            
           
             setLoad(true)
       
          } catch (error) {
              console.log("error", error);
          }
        }
      };
  
      fetchData();
  }, [props.lang, props.index, props.tabs, jsonLoaded, issues]);
 
 /*  console.log("props.index:", props.index, "props.hasIntro:", props.hasIntro, "props.hasTabIntro" , props.hasTabsIntro, "props.tabs: ",props.tabs); */
 /*  console.log("multiples?", issues.issues !== undefined && issues.issues.filter(elem => props.index.includes(elem.issue_ID)))
  console.log("array?", Array.isArray(props.index))  */
   
    return (
    <React.Fragment>   
         <button style={{float: props.lang !== "ar" ? 'right' : "left", border: "none", backgroundColor: "white", marginLeft: "5rem", marginRight: "5rem"}} onClick={() =>
          exportPdfTable(t(props.argument), 
          Array.isArray(props.index) ? 
          issues.issues.filter(elem => props.index.includes(elem.issue_ID)).sort((a, b) => a.issue_ID > b.issue_ID ? 1 : -1) : issues.issues.find(elem => elem.issue_ID === props.index), 
          issues.issues.find(elem => elem.issue_ID === props.hasIntro),
          [t('questions'), t('answer'), t('explanation'), t('date_of_text'), t('date_of_original_text'), t('last_amended_date'), t('faolex_number'), t('source')],
          issues.issues !== undefined && issues.issues.find(elem => elem.issue_ID === props.hasTabsIntro),
          props.lang)}>
         <img  className="download-icon" src={process.env.PUBLIC_URL + `/img/file-earmark-pdf-fill.svg`} alt="download pdf icon" />
         </button>
        <Row style={{border: "none", borderRadius: "0px!important", paddingRight: "10px"}} > 
          <Col style={{ border: "none", borderRadius: "0px!important", paddingLeft:"0px", paddingRight:"0px"}}  sm={open ? 3 : 0}> 
          {!open &&  <Col style={{ width: "40px", minHeight: "800px", backgroundColor: "#E5ECF4"}}>
            <Button  style={{width: "40px", backgroundColor: "#E5ECF4", border: "none"}} onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
            <img style={{ border: "none", backgroundColor: "#E5ECF4"}}src={process.env.PUBLIC_URL + `/img/list.svg`} alt="hamburger open menu icon" />
            </Button></Col>}

            {open && <div className="alartPage" style={{ minHeight: '1000px' }}>
            <Collapse in={open} dimension="width" style={{  maxWidth: "300px", miWidth:  open ? '300px' : "80px" }}>
             <div><Accordion  style={{minHeight: "800px"}}>
              <Col  md={{ span: 11, offset: 11 }}>
              <CloseButton  style={{textAlign: "right", paddingLeft:props.lang === "ar" ?"20px" :"0px", paddingRight:"0px", height: "20px" }}  onClick={() => setOpen(!open)}/>
              </Col> 
            <AlartSideBar lang={props.lang}  />
            </Accordion></div>
        </Collapse>
        </div>}
        </Col>


        <Col sm={open ? 7 : 11} lg={open ? 9 : 11} style={{ paddingLeft:"20px", paddingRight:"20px"}} >    
        {props.tabs !== undefined && issues.issues !== undefined ? 
        <AlartTabs 
        issues={issues.issues !== undefined && issues.issues.filter(elem => props.index.includes(elem.issue_ID))} 
        hasIntro={issues.issues !== undefined && issues.issues.find(elem => elem.issue_ID === props.hasIntro)} 
        hasTabsIntro={issues.issues !== undefined && issues.issues.find(elem => elem.issue_ID === props.hasTabsIntro)} 
        tabs={props.tabs} lang={props.lang}  argument={props.argument}/> :
        issues.issues !== undefined &&
         <AlartContentColumn lang={props.lang} isTab={false}
         issue={issues.issues !== undefined && issues.issues.find(elem => elem.issue_ID === props.index)} 
         hasIntro={issues.issues !== undefined && issues.issues.find(elem => elem.issue_ID === props.hasIntro)} 
         argument={props.argument}   />
          
          } 
         </Col>


      </Row>
      </React.Fragment>
    );
  }

  export default AlartPage;