import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import {Link} from "react-router-dom";




function NormativeFrameworksSideBar(props) {

  const { t } = useTranslation()



  const location = useLocation()
  const [coloredMainMenu, SetColorMain] = useState("");
  const [coloredSubMenuNational, SetColorNat] = useState("");
  const [coloredSubMenuInt, SetColorInt] = useState("");



  useEffect(() => {
     
   SetColorMain(location.pathname.includes("international"));
   SetColorNat(location.pathname.includes("aquaculture") && !location.pathname.includes("schemes"));
   SetColorInt(location.pathname.includes("treaties"));
   
  }, [location.pathname, props.lang, coloredMainMenu, coloredSubMenuInt, coloredSubMenuNational])


  return (

    <React.Fragment>
  
  <div className={`${props.lang === "ar" ? "arabic-chevron side-menu-bar normative" : "side-menu-bar normative"}`}  >
  <Link className={coloredMainMenu ? "" : "make-link-active"} to={`/${props.lang}/normative-frameworks/national/aquaculture`}><h6>{t('national_instruments')}</h6></Link>
    <ul className="treaties-sub-menu">

   <li><Link className={coloredSubMenuNational ? "make-link-active" : ""} to={`/${props.lang}/normative-frameworks/national/aquaculture`}>{t("aquaculture")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/land-tenure`}>{t("land_tenure")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/water-resources-tenure`}>{t("water_resources_tenure")}</Link></li>

   <li><Link to={`/${props.lang}/normative-frameworks/national/protected-areas`}>{t("protected_areas")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/iczm-coastal-zone-management`}>{t("iczm_coastal")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/land-use-planning`}>{t("land_use_planning")}</Link></li>

   <li><Link to={`/${props.lang}/normative-frameworks/national/marine-spatial-planning`}>{t("marine_spatial_planning")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/environmental-impact-assessment`}>{t("assessment_env_imp")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/animal-health`}>{t("animal_health")}</Link></li>

   <li><Link to={`/${props.lang}/normative-frameworks/national/plant-health`}>{t("plant_health")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/animal-feed`}>{t("animal_feed")}</Link></li>
   <li><Link to={`/${props.lang}/normative-frameworks/national/veterinary-drugs`}>{t("veterinary_drugs")}</Link></li>
</ul>
</div>
<div className={`${props.lang === "ar" ? "arabic-chevron side-menu-bar normative" : "side-menu-bar normative"}`}>
<Link  className={coloredMainMenu ? "make-link-active" : ""} to={`/${props.lang}/normative-frameworks/international/treaties`}><h6>{t('international_instruments')}</h6></Link>
    <ul className="treaties-sub-menu">

   <li><Link  className={coloredSubMenuInt ? "make-link-active" : ""} to={`/${props.lang}/normative-frameworks/international/treaties`}>{t("treaties")}</Link></li>
   <li><Link  to={`/${props.lang}/normative-frameworks/international/soft-law-instruments-and-guidelines`}>{t("soft_law")}</Link></li>
   <li><Link  to={`/${props.lang}/normative-frameworks/international/aquaculture-certification-schemes`}>{t("aquaculture_certification")}</Link></li>
</ul>
</div>


    </React.Fragment>
    );
  }

  export default NormativeFrameworksSideBar;