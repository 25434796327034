import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";





function AlartSideBar(props) {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const handleClick = (route) => () => {
    navigate(route, {replace: true})
  };

  const location = useLocation()
  const [color, SetColor] = useState("");



  useEffect(() => {
     
   SetColor(location.pathname.includes("policy-issues"));

  }, [location.pathname, props.lang])

  return (
 
    <React.Fragment>
    <Accordion.Item  className={'no-arrow '} eventKey="0" >
       <Accordion.Header style={{borderTop: "1px solid #adb5bd" }} onClick={handleClick(`/${props.lang}/alart/policy-issues`)}><span className={color ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>1. {t('policy_issues')}</span></Accordion.Header>
      </Accordion.Item>

         <Accordion.Item eventKey="1" className="no-arrow">
         <Accordion.Header onClick={handleClick(`/${props.lang}/alart/institutional-arrangements`)}>
          <span className={location.pathname.includes("institutional-arrangements") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>2. {t('institutional_arrangements')}</span>
          </Accordion.Header>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className={props.lang === "ar" ? "arabic-fix" : ""}>
      <Accordion.Header onClick={handleClick(`/${props.lang}/alart/tenure-arrangements/land-tenure`)}>
        <span className={location.pathname.includes("tenure-arrangements") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>3. {t('tenure_arrangements')}</span>
        </Accordion.Header>
        <Accordion.Body>
        
        <Accordion>
        <Accordion.Item eventKey="3" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/tenure-arrangements/land-tenure`)}>
          <span className={location.pathname.includes("land-tenure") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>3.1 {t('land_tenure')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/tenure-arrangements/water-tenure`)}>
          <span className={location.pathname.includes("water-tenure") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>3.2 {t('water_tenure')}</span></Accordion.Header>
       </Accordion.Item>
      </Accordion>

        </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="5" className={props.lang === "ar" ? "arabic-fix" : ""}>
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/land-use-planning`)}>
          <span className={location.pathname.includes("planning-and-approval") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4. {t('planning_and_approval')}</span></Accordion.Header>
        <Accordion.Body>
        <Accordion>
        <Accordion.Item eventKey="6" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/land-use-planning`)}>
          <span className={location.pathname.includes("land-use-planning") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4.1 {t('land_use_planning')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="7" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/marine-legislation`)}>
          <span className={location.pathname.includes("marine-legislation") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4.2 {t('marine_legislation')}</span></Accordion.Header>
       </Accordion.Item>
       <Accordion.Item eventKey="8" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/floods-droughts-and-storm-surges`)}>
          <span className={location.pathname.includes("floods-droughts-and-storm-surges") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4.3 {t('floods_droughts_storm')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="9" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/coastal-zone-management-planning`)}>
          <span className={location.pathname.includes("coastal-zone-management-planning") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4.4 {t('coastal_zone_man')}</span></Accordion.Header>
       </Accordion.Item>
       <Accordion.Item eventKey="10" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/environmental-legislation`)}>
          <span className={location.pathname.includes("environmental-legislation") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4.5 {t('environ_legislation')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="11" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/planning-and-approval/acquaculture-legislation`)}>
          <span className={location.pathname.includes("acquaculture-legislation") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>4.6 {t('aquaculture_legislation')}</span></Accordion.Header>
       </Accordion.Item>
      </Accordion>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="12" className={props.lang === "ar" ? "arabic-fix" : ""}>
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-inputs/aquatic-animals`)}>
          <span className={location.pathname.includes("production-inputs") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>5. {t('production_inputs')}</span></Accordion.Header>
        <Accordion.Body>
        <Accordion>
        <Accordion.Item eventKey="13" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-inputs/aquatic-animals`)}>
          <span className={location.pathname.includes("aquatic-animals") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>5.1 {t('animals_acquatic')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="14" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-inputs/aquatic-plants`)}>
          <span className={location.pathname.includes("aquatic-plants") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>5.2 {t('plants_aquatic')}</span></Accordion.Header>
       </Accordion.Item>
       <Accordion.Item eventKey="15" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-inputs/aquatic-animal-feed`)}>
          <span className={location.pathname.includes("aquatic-animal-feed") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>5.3 {t('aquatic_animal_feed')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="16" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-inputs/medicines`)}>
          <span className={location.pathname.includes("medicines") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>5.4 {t('medicines')}</span></Accordion.Header>
       </Accordion.Item>
       <Accordion.Item eventKey="17" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-inputs/chemicals-pesticides`)}>
          <span className={location.pathname.includes("chemicals-pesticides") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>5.5 {t('chemicals_pesticides')}</span></Accordion.Header>
        </Accordion.Item>
      </Accordion>
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="18" className={props.lang === "ar" ? "arabic-fix" : ""}>
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-facility-management/general`)}>
          <span className={location.pathname.includes("production-facility-management") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>6. {t('production_facility_management')}</span></Accordion.Header>
        <Accordion.Body>
        <Accordion>
        <Accordion.Item eventKey="19" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-facility-management/general`)}>
          <span className={location.pathname.includes("production-facility-management/general") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>6.1 {t('general')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="20" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-facility-management/environmental-impacts`)}>
          <span className={location.pathname.includes("environmental-impacts") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>6.2 {t('environmental_impacts')}</span></Accordion.Header>
       </Accordion.Item>
       <Accordion.Item eventKey="21" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/production-facility-management/animal-and-plant-health-management`)}>
          <span className={location.pathname.includes("animal-and-plant-health-management") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>6.3 {t('animal_plant_health_man')}</span></Accordion.Header>
        </Accordion.Item>
      </Accordion>
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="22" className={props.lang === "ar" ? "arabic-fix" : ""}>
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/disease-prevention-and-control/planning-surveillance-and-preparation`)} flush="true">
          <span className={location.pathname.includes("disease-prevention-and-control") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>7. {t('disease_prevention_and_control')}</span></Accordion.Header>
        <Accordion.Body>
        
        <Accordion>
        <Accordion.Item eventKey="23" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/disease-prevention-and-control/planning-surveillance-and-preparation`)}>
          <span className={location.pathname.includes("planning-surveillance-and-preparation") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>7.1 {t('planning_surveillance')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="24" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/disease-prevention-and-control/response-measures`)}>
          <span className={location.pathname.includes("response-measures") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>7.2 {t('response_measures')}</span></Accordion.Header>
       </Accordion.Item>
      </Accordion>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="25" flush="true" className={props.lang === "ar" ? "arabic-fix" : ""}>
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/post-production/food-safety-traceability`)} flush="true">
          <span className={location.pathname.includes("post-production") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>8. {t('post_production')}</span></Accordion.Header>
        <Accordion.Body>
        <Accordion>
        <Accordion.Item eventKey="26" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/post-production/food-safety-traceability`)}>
          <span className={location.pathname.includes("food-safety-traceability") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>8.1 {t('food_safety_traceability')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="27" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/post-production/export-of-life-aquatic-animals`)}>
          <span className={location.pathname.includes("export-of-life-aquatic-animals") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>8.2 {t('export_aquatic_animals')}</span></Accordion.Header>
       </Accordion.Item>
       <Accordion.Item eventKey="28" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/post-production/export-of-aquatic-plants`)}>
          <span className={location.pathname.includes("export-of-aquatic-plants") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>8.3 {t('export_aquatic_plants')}</span></Accordion.Header>
       </Accordion.Item>
      </Accordion>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="29" flush="true" className={props.lang === "ar" ? "arabic-fix" : ""}>
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/inspection-and-enforcement/inspection`)} flush="true">
          <span className={location.pathname.includes("inspection-and-enforcement") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>9. {t('inspection_and_enforcement')}</span></Accordion.Header>
        <Accordion.Body>
        <Accordion>
        <Accordion.Item eventKey="30" className="no-arrow no-padding" >
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/inspection-and-enforcement/inspection`)}>
          <span className={location.pathname.includes("nspection-and-enforcement/inspection") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>9.1 {t('inspection')}</span></Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="31" className="no-arrow no-padding">
        <Accordion.Header onClick={handleClick(`/${props.lang}/alart/inspection-and-enforcement/enforcement`)}>
          <span className={location.pathname.includes("inspection-and-enforcement/enforcement") ? 'make-it-active alart-side-bar-text' : 'alart-side-bar-text'}>9.2 {t('enforcement')}</span></Accordion.Header>
       </Accordion.Item>
      </Accordion>
        </Accordion.Body>
      </Accordion.Item>
    </React.Fragment>



  );
}

export default AlartSideBar;