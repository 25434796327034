

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/Footer.css'; 




function Footer(props) {
    const { t } = useTranslation()

   


    useEffect(() => {
     
     
    }, [props.lang])
    return (
        <div className="footer" >
      <Row style={{marginLeft: "15px", marginRight: "15px"}}> 
        <Col sm={9} >
        <span className="footer_span"><a href="https://www.fao.org/contact-us/en/ " target="_blank ">{t('contact_us')}</a></span>
                <span className="footer_span"><a href="https://www.fao.org/contact-us/terms/en/" target="_blank ">{t('terms_and_conditions')}</a></span>
                <span className="footer_span" ><a href="https://www.fao.org/contact-us/scam-alert/en/ " target="_blank ">{t('scam_alert')}</a></span>
                <span className="footer_span"><a href="https://www.fao.org/contact-us/report-misconduct/en" target="_blank ">{t('report_misconduct')}</a></span>
        </Col>
        <Col sm={3}><div className="footer-at-fao">&#169; FAO, 2023</div></Col>
            
   
    </Row>
    </div>
    );}

    export default Footer;

