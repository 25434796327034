import pdfMake from 'pdfmake/build/pdfmake';
import logos from '../../styles/logos.json';




const summaryGeneral = (issue, type) => {
   console.log(issue, type);
   const summaryGen = [];
    if(issue !== undefined && Array.isArray(issue.summary)) {

      if(type === "gen") {
        console.log("eneting here")
        summaryGen.push({text:issue.issue_no_level1 + ". " + issue.issue_en_level1 + '\n\n', fontSize: 10, bold: true})
      } else {
        summaryGen.push({text:issue.issue_no_level2 + ". " + issue.issue_en_level2 + '\n\n', fontSize: 10, bold: true})
      }

        console.log("here in summaryGeneral")
	 issue.summary.map(item => {
  return summaryGen.push({text:item  + '\n\n', fontSize: 9})
	});
 
}
return summaryGen;
}


const Question = (issue, titles) => {
    const contentToDisplay = [];
    
   for (let i = 0; i < issue.questions.length ; i++) {
 
    contentToDisplay.push( 
      {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1 }]},
      {text: issue.questions[i].question_no + ". " + issue.questions[i].question_en + '\n\n', fontSize: 12, style: 'question' },);
    contentToDisplay.push({text: titles[1].toUpperCase() + "\n\n", fontSize: 10, bold:true})
       issue.questions[i].question_if.map(answer =>  contentToDisplay.push({text:answer  + '\n\n', fontSize: 9}))
       issue.questions[i].explanations !== undefined && issue.questions[i].explanations.map(expla =>  
        contentToDisplay.push({text:  titles[2].toUpperCase() + "\n\n", fontSize: 10, bold: true}, 
        {text: expla.explanation + '\n\n', fontSize: 9}, 
        
        
        
        expla.examples !== undefined ?
        expla.examples.map((exa, id) => { return [
          
          {text: [{ text: exa.country + ": " , style: "country" }, {text:  exa.title_of_text, link: exa.url_pdf, style: "textTitle"}]}, 
        {text:  exa.date_of_text !== undefined ? titles[3].concat(": ").concat(exa.date_of_text).concat(" ")  : exa.date_of_original_text !== undefined ? titles[4].concat(": ").concat(exa.date_of_original_text).concat(" ").concat(exa.last_amended_date !== undefined ? titles[5] + ": " + exa.last_amended_date : "") : "", fontSize: 9},
        {text: [{text: titles[6] + ": "}, { text: exa.id + '\n\n', link: exa.url_faolex, style: "link",}], fontSize: 9}, 
        {text: exa.measure !== undefined ? exa.measure + '\n' : "", decoration: "underline", fontSize: 9},
        {text: exa.comments + '\n', fontSize: 9}, 
        {text: titles[7] + ": " + exa.source + '\n\n', fontSize: 9},
         {canvas: id < expla.examples.length - 1 ? [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 0.5,  lineColor: "#BDBDBD" }] : []},
        ]}) : {text: ""}
        
        
        ))
      

      
      }
      return contentToDisplay;
}


const Tabs = (tabsIssues, titles) => {
const tabElements = [];
  for (let i = 0; i < tabsIssues.length ; i++) {
    console.log(tabsIssues[i]);
    tabElements.push({text: tabsIssues[i].issue_en_level3 + '\n\n', fontSize: 13, bold: true, decoration:"underline"})
    tabElements.push(Question(tabsIssues[i], titles));
  }

return tabElements;
}


function AlartToolPDFBuilder (arg, mainIssue, intro, titles, tabIntro, language) {
/* console.log(Array.isArray(mainIssue))
console.log("mainIssue"+ mainIssue) */
console.log("tabIntro", tabIntro)



	const generateGeneralSummary =  intro !== undefined ?  summaryGeneral(intro, "gen") : '';
 
    const generateIssueSummary =  mainIssue !== undefined ? !Array.isArray(mainIssue) ? summaryGeneral(mainIssue, mainIssue.issue_no_level1 === '1' || mainIssue.issue_no_level1 === '2' ? "gen" : "spec") : '' : '';
    const generateTabsSummary =  tabIntro !== undefined ?  summaryGeneral(tabIntro, "spec") : '';
    const generateQuestions =  mainIssue !== undefined ? !Array.isArray(mainIssue) ? Question(mainIssue, titles) : Tabs(mainIssue, titles) : '';
   const today = new Date().toLocaleString();
   // console.log(generateQuestions);




  const documentDefinition  = {
		pageSize: 'A4',
		pageOrientation: 'portrait',
  
    footer: function(currentPage, pageCount) { return [{text: currentPage.toString() + ' of ' + pageCount, alignment: "center" },{text:today.toLocaleString().substring(0, 10), alignment: "right", margin: [10,10,10,20]}]},
    
    
    
		content: [


     
       
    {  columns:  [ 
      {
        image: logos[language.concat('_logo')],   
       width: 200,

  },   
      {text: 'ALART Online', link: "http://localhost:3000/en/alart/policy-issues", color: "#116AAB", bold:true, fontSize:13, alignment: 'right'}],
    },
			'\n',
           
            ...generateGeneralSummary,
            ...generateTabsSummary,
            ...generateIssueSummary,
            ...generateQuestions,
		],
    styles: {
      question: {
        fontSize: 11,
        bold: true
      },
        country: {
          fontSize: 10,
          bold: true
          //decoration: 'underline'
        },
       textTitle: {
          bold: true,
         fontSize: 10,
         color: '#116AAB',
         decoration: "underline"
        },
        link: {
         fontSize: 10,
         color: '#116AAB',
         decoration: "underline",
         bold: true,
        }
    }
    
  };

	pdfMake.createPdf(documentDefinition).download("ALART - " + arg);
}

export default AlartToolPDFBuilder;