
import React from 'react';


function AboutPage(props) {

    return (
      <React.Fragment>
     <p style={{minHeight: "30rem"}}>About page: work in progress...</p>
      </React.Fragment>
    );
  }

  export default AboutPage;