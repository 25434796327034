import React,  { useEffect }  from 'react'
import cookies from 'js-cookie'
import { Routes, Route, Navigate } from "react-router-dom";
import {languages} from './i18n.js'

import HomePage  from './pages/Homepage';
import AboutPage from './pages/AboutPage';
import NormativeFrameworksPage from './pages/NormativeFrameworksPage';
import ErrorPage from './pages/ErrorPage';
import AlartPage from './pages/AlartPage';

import MultilanguageNavigationBar from './components/NavBar'
import Footer from './components/Footer'




export default function App() {

  const currentLanguageCode = cookies.get('i18next') ||  'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const currentLangTitle = languages.find((l) => l.code === currentLanguageCode).name
  
  
    useEffect(() => {
      document.body.dir = currentLanguage.dir || 'ltr'
     
    }, [currentLanguage])

  return (
    <div>
       <div>
     <MultilanguageNavigationBar  lang={currentLanguageCode} langTitle={currentLangTitle}/>
     </div>
     <div >
    
     <Routes>
    <Route
        path="*"
        element={<Navigate to="/en" replace />}
    /> 
      <Route path={currentLanguageCode} element={<HomePage lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/about`} element={<AboutPage lang={currentLanguageCode} />} />

      <Route path={`/${currentLanguageCode}/normative-frameworks/national/aquaculture`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_aquaculture" argument="Aquaculture" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/land-tenure`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_land_tenure" argument="Land Tenure" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/water-resources-tenure`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_water_resources" argument="Water Resources/Tenure" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/protected-areas`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_protected_areas" argument="Protected Areas" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/iczm-coastal-zone-management`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_iczm" argument="ICZM Coastal Zone Management" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/land-use-planning`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_land_use" argument="Land Use Planning" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/marine-spatial-planning`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_marine_spatial" argument="Marine Spatial Planning" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/environmental-impact-assessment`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_env_imp_ass" argument="Environmental Impact Assessment" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/animal-health`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_animal_health" argument="Animal Health" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/plant-health`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_plant_health" argument="Plant Health" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/animal-feed`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_animal_feed" argument="Animal Feed" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/national/veterinary-drugs`} element={<NormativeFrameworksPage type="national" pageTitle="national_title_veterinary_drugs" argument="Veterinary Drugs" lang={currentLanguageCode} />} />



      <Route path={`/${currentLanguageCode}/normative-frameworks/international/treaties`} element={<NormativeFrameworksPage type="international" argument="Treaties" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/international/soft-law-instruments-and-guidelines`} type="international" element={<NormativeFrameworksPage argument="Soft law instruments and guidelines" lang={currentLanguageCode} />} />
      <Route path={`/${currentLanguageCode}/normative-frameworks/international/aquaculture-certification-schemes`} type="international" element={<NormativeFrameworksPage argument="Aquaculture certification schemes" lang={currentLanguageCode} />} />

     
      <Route path={`/${currentLanguageCode}/alart/policy-issues`} element={<AlartPage argument="policy_issues"  index="SEC-000281" lang={currentLanguageCode} />} />
      
      <Route path={`/${currentLanguageCode}/alart/institutional-arrangements`} element={<AlartPage argument="institutional_arrangements" index="SEC-000282" lang={currentLanguageCode} />} />
      
   
      <Route path={`/${currentLanguageCode}/alart/tenure-arrangements/land-tenure`}  element={<AlartPage  hasIntro="SEC-000393" argument="land_tenure" index="SEC-000668" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/tenure-arrangements/water-tenure`} element={<AlartPage  argument="water_tenure" index="SEC-000669" lang={currentLanguageCode}  />} />
     
    
      <Route path={`/${currentLanguageCode}/alart/planning-and-approval/land-use-planning`} element={<AlartPage  hasIntro="SEC-000283" argument="land_use_planning" index="SEC-000670" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/planning-and-approval/marine-legislation`} element={<AlartPage  argument="marine_legislation" index="SEC-000671" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/planning-and-approval/floods-droughts-and-storm-surges`} element={<AlartPage  argument="floods_droughts_storm" index="SEC-000672" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/planning-and-approval/coastal-zone-management-planning`} element={<AlartPage  argument="coastal_zone_man" index="SEC-000673" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/planning-and-approval/environmental-legislation`} element={<AlartPage  argument="environ_legislation" index="SEC-000674" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/planning-and-approval/acquaculture-legislation`} element={<AlartPage  hasTabsIntro="SEC-000675" tabs={["planning", "auth_approval", "appeal"]} argument="aquaculture_legislation" index={[ "SEC-000676", "SEC-000677", "SEC-000678" ]} lang={currentLanguageCode}  />} />
   
    
      <Route path={`/${currentLanguageCode}/alart/production-inputs/aquatic-animals`} element={<AlartPage  
      hasIntro="SEC-000284" hasTabsIntro="SEC-000679" tabs={["import_acquatic_animals","breeding_acquatic_animals","movement_acquatic_animals"]} 
      argument="animals_acquatic"  index={["SEC-000680", "SEC-000681", "SEC-000682"]} lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/production-inputs/aquatic-plants`} 
      element={<AlartPage tabs={["import_aquatic_plants" ,"placing_aquatic_plant"]} argument="plants_aquatic" hasTabsIntro="SEC-000683" index={["SEC-000684", "SEC-000685"]} lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/production-inputs/aquatic-animal-feed`} element={<AlartPage  argument="aquatic_animal_feed" index="SEC-000686" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/production-inputs/medicines`} element={<AlartPage  
      hasTabsIntro="SEC-000687" tabs={["medicines", "import_medicines", "use" ]} argument="medicines" index={["SEC-000688", "SEC-000689", "SEC-000690"]} lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/production-inputs/chemicals-pesticides`} element={<AlartPage  argument="chemicals_pesticides" index="SEC-000691" lang={currentLanguageCode}  />} />

    
      <Route path={`/${currentLanguageCode}/alart/production-facility-management/general`} element={<AlartPage  hasIntro="SEC-000285" argument="general" index="SEC-000692" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/production-facility-management/environmental-impacts`} element={<AlartPage  argument="environmental_impacts" 
      tabs={[ "environmental_impacts", "escapes", "protected_animals", "lost_gear_equipment"]} index={["SEC-000694", "SEC-000695", "SEC-000696", "SEC-000697" ]} lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/production-facility-management/animal-and-plant-health-management`} element={<AlartPage  argument="animal_plant_health_man" index="SEC-000698" lang={currentLanguageCode}  />} />


      <Route path={`/${currentLanguageCode}/alart/disease-prevention-and-control/planning-surveillance-and-preparation`} element={<AlartPage hasIntro="SEC-000286" argument="planning_surveillance" index="SEC-000699" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/disease-prevention-and-control/response-measures`} element={<AlartPage argument="response_measures" index="SEC-000700" lang={currentLanguageCode}  />} />


     <Route path={`/${currentLanguageCode}/alart/post-production/food-safety-traceability`} element={<AlartPage  hasIntro="SEC-000287" argument="food_safety_traceability" index="SEC-000701" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/post-production/export-of-life-aquatic-animals`} element={<AlartPage  argument="export_aquatic_animals" index="SEC-000702" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/post-production/export-of-aquatic-plants`} element={<AlartPage  argument="export_aquatic_plants" index="SEC-000703" lang={currentLanguageCode}  />} />

    
      <Route path={`/${currentLanguageCode}/alart/inspection-and-enforcement/inspection`} element={<AlartPage hasIntro="SEC-000288" argument="inspection" index="SEC-000704" lang={currentLanguageCode}  />} />
      <Route path={`/${currentLanguageCode}/alart/inspection-and-enforcement/enforcement`} element={<AlartPage  argument="enforcement" index="SEC-000705" lang={currentLanguageCode}  />} />

        
      
      <Route path="*" element={<ErrorPage />} />
      </Routes>
      </div>
      <Footer lang={currentLanguageCode} />
   




   
    </div>
  )
}