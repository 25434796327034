
import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import CloseButton from 'react-bootstrap/CloseButton';

import NormativeFrameworksContentColumn from '../components/normative_frameworks/NormativeFrameworksContentColumn';
import NormativeFrameworksSideBar from '../components/normative_frameworks/NormativeFrameworksSideBar';

import '../styles/NormativeFrameworks.css'; 

import NormativeFrameworksPDFBuilder from '../components/normative_frameworks/pdfGenerateNormativeFr';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useTranslation } from 'react-i18next';



pdfMake.vfs = pdfFonts.pdfMake.vfs;
function exportPdfTable(data, titles, pageTitle, type, norFraTitle, norFraSummary, language) {

  NormativeFrameworksPDFBuilder (data, titles, pageTitle, type, norFraTitle, norFraSummary, language);
}


function NormativeFrameworksPage(props) {
 
 
  const [open, setOpen] = useState(true);
    const [data, setData] = useState("");
      const[jsonLoaded, setLoad] = useState(false);
      const [type, setType] = useState(props.type);
      const { t } = useTranslation();


    useEffect(() => {
    
      const urlNational = "https://faolex.fao.org/docs/json/alartLeg.json";
      const urlInternational = "https://faolex.fao.org/docs/json/alartIns.json";
  
      if (props.type !== type) {
        setLoad(false);
        setType(props.type);
      }

      const fetchData = async () => {
        if(jsonLoaded === false) {
          try {
              const response = await fetch(props.type === "national" ? urlNational : urlInternational);
              const json = await response.json();
             
                setData(json);
            
            
             setLoad(true)
       
          } catch (error) {
              console.log("error", error);
          }
        }
      };
  
      fetchData();
    
  }, [props.lang, props.type, jsonLoaded, data, type]);
 
    

  return (
 
  <React.Fragment>  
      <button style={{float: props.lang !== "ar" ? 'right' : "left", border: "none", backgroundColor: "white", marginLeft: "5rem", marginRight: "5rem"}} 
      onClick={() => 
      exportPdfTable(data.areas !== undefined && data.areas.find(elem => elem.name === props.argument), 
      [t('jurisdiction'), t('title_text'), t('year_of_text'),t('year_amended_date') ,t('faolex_number')], 
      props.type === "national" ? t(props.pageTitle) : props.argument, 
      props.type,
      props.type === "national" ?  t('national_instruments') : t('international_instruments'),
      props.argument === "Soft law instruments and guidelines" ? 
      t('soft_law_int_normative_frameworks_intro') : 
      props.argument === "Aquaculture certification schemes" ? 
      t('aquaculture_int_normative_frameworks_intro') : '',
      props.lang)}>

         <img  className="download-icon" src={process.env.PUBLIC_URL + `/img/file-earmark-pdf-fill.svg`} alt="download pdf icon" />
         </button>
     <Row style={{border: "none", borderRadius: "0px!important", paddingRight: "10px"}} > 
        <Col style={{ border: "none", borderRadius: "0px!important", paddingLeft:"0px", paddingRight:"0px"}}  sm={open ? 3 : 0}> 
        {!open &&  <Col style={{ width: "40px", minHeight: "800px", backgroundColor: "#E5ECF4"}}>
          <Button  style={{width: "40px", backgroundColor: "#E5ECF4", border: "none"}} onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
          <img style={{ border: "none", backgroundColor: "#E5ECF4"}}src={process.env.PUBLIC_URL + `/img/list.svg`} alt="hamburger open menu icon" />
          </Button></Col>}

          {open && <div className="alartPage" style={{ minHeight: '1000px' }}>
          <Collapse in={open} dimension="width" style={{  maxWidth: "300px", miWidth:  open ? '300px' : "80px" }}>
           <div><Accordion  style={{minHeight: "800px"}}>
            <Col  md={{ span: 11, offset: 11 }}>
            <CloseButton  style={{textAlign: "right", paddingLeft:props.lang === "ar" ?"20px" :"0px", paddingRight:"0px", height: "20px" }}  onClick={() => setOpen(!open)}/>
            </Col> 
          <NormativeFrameworksSideBar lang={props.lang}  />
          </Accordion></div>
      </Collapse>
      </div>}
      </Col> 


      <Col sm={open ? 7 : 11} lg={open ? 9 : 11} style={{ paddingLeft:"20px", paddingRight:"20px"}} >    
     
        <NormativeFrameworksContentColumn pageTitle={props.pageTitle} lang={props.lang} argument={props.argument} data={data.areas !== undefined && data.areas.find(elem => elem.name === props.argument)} /> 
       </Col>
 </Row> 

   
    </React.Fragment>
    );
  }

  export default NormativeFrameworksPage;