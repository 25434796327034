import { initReactI18next } from 'react-i18next';
import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const languages = [
  {
    code: 'en',
    name: 'English',
  },
  
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'es',
    name: 'Español',
  },
  
  {
    code: 'ru',
    name: 'Русский',
  },
  {
    code: 'zh',
    name: '中文',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
  },
]

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr', 'es', 'ru', 'zh'],
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  })

  export {languages}
 