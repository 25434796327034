import React from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/Homepage.css'; 



function HomePage(props) {
    const { t } = useTranslation()

    return (
      <React.Fragment>
         <div
      className="article"
      style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0)), url(${process.env.PUBLIC_URL + '/img/homepage_banner.jpg'})`,  }}> 
      <Row style={{border: "none", borderRadius: "0px!important", paddingTop: "80px", paddingLeft:"100px", paddingRight:"100px",}} >



       <Col   style={{border: "none", borderRadius: "0px!important",  paddingTop:"20px"}}  sm={8}>
      <h6 className="header">{t('alart_title')}</h6>
    <p className="header">{t('alart_first_paragraph')}</p>
    <p className="header">{t('alart_second_paragraph')}</p>
    <Link  to={`/${props.lang}/alart/policy-issues`} style={{textDecoration: 'none'}}> <span style={{backgroundColor:"white", paddingTop:"1px", paddingBottom:"4px", paddingLeft:"5px", paddingRight:"5px", borderRadius:"4px"}}><img style={{ verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/bookmark-check.svg`} alt="logo bookmark Alart tool" /></span><span style={{color:"white", paddingLeft:"8px", paddingRight:"8px"}}>{t('revision_tool')}</span> </Link>

    <a  target="_blank" rel="noreferrer" href="https://www.fao.org/faolex/results/en/#subset=ALART#querystring=?search=adv" style={{paddingLeft: "50px", paddingRight:"50px", textDecoration: 'none'}}> <span style={{backgroundColor:"white", paddingTop:"1px", paddingBottom:"4px", paddingLeft:"5px", paddingRight:"5px", borderRadius:"4px"}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/search.svg`} alt="Alart advanced search" /></span><span style={{color:"white", paddingLeft:"8px", paddingRight:"8px"}}>{t('advanced_search')}</span> </a>

    </Col>
    <Col sm={4} style={{ paddingLeft:"0px", paddingRight:"0px"}} >    </Col>
    </Row>
     
    </div>
    <Row style={{border: "none"}} >



<Col    style={{border: "none", borderRadius: "0px!important", margin:"2em"}}  xs={7}>
 <Row className="homepage-row" style={{border: "none", borderRadius: "0px!important", padding:"2rem"}}>
 <Col sm={3}>
 <img src={process.env.PUBLIC_URL + `/img/book.jpeg`} alt="book ALART related publications" />
 </Col>
 <Col sm={8}>
 <h6>{t('1_title_homepage_publication')}</h6>
  <p>{t('1_paragraph_homepage_publication')}</p>
  </Col></Row>

 <Row className="homepage-row" style={{border: "none", borderRadius: "0px!important", padding:"2rem", marginTop:"2rem"}}>
 <Col sm={3}>
 <img src={process.env.PUBLIC_URL + `/img/book.jpeg`} alt="book ALART related publications" />
 </Col>
 <Col sm={8}>
 <h6>{t('2_title_homepage_publication')}</h6>
  <p>{t('2_paragraph_homepage_publication')}</p>
  </Col></Row>
 
  </Col>
<Col style={{ borderTop: "2px solid #E8E8E8", borderRadius: "0px!important",   margin:"2rem", padding:"3rem" }} > 

 <h6>{t('related_links')}</h6>
 <p><a target="_blank" rel="noreferrer" href="https://www.fao.org/asiapacific/perspectives/rtp-aquaculture/en/"  style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"black", paddingLeft:"8px", paddingRight:"8px"}}>Aquaculture Regional Technical Platform (AQ-RTP)</span> </a></p>
 <p><a target="_blank" rel="noreferrer" href="https://www.fao.org/faolex/en/"  style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"black", paddingLeft:"8px", paddingRight:"8px"}}>FAOLEX</span> </a></p>
 <p><a target="_blank" rel="noreferrer" href="https://www.fao.org/fishery/en/collection/legalframework" style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"black", paddingLeft:"8px", paddingRight:"8px"}}>NALO</span> </a> </p>
 <p><a target="_blank" rel="noreferrer" href="https://ssflex.fao.org/"  style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"black", paddingLeft:"8px", paddingRight:"8px"}}>SSF-LEX</span> </a></p>
 <p><a target="_blank" rel="noreferrer" href="https://amr-lex.fao.org/" style={{textDecoration: 'none'}}><img style={{    verticalAlign: "text-bottom"}}src={process.env.PUBLIC_URL + `/img/globe.svg`} alt="globe icon external link" /><span style={{color:"black", paddingLeft:"8px", paddingRight:"8px"}}>AMR-LEX</span> </a> </p>

<div className='contact-box'>
<h6>{t('contact')}</h6>
<p>{t('info_contact') + " "}<a href="mailto:faolex@fao.org">
faolex@fao.org
</a></p>
</div>
  </Col>
</Row>
      </React.Fragment>
    );
  }

  export default HomePage;