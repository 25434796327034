import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import i18next from 'i18next'
import {languages} from '../i18n.js'
import { useLocation } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import { useNavigate } from "react-router-dom";

import   { useEffect }  from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../App.css'; 
//import Form from 'react-bootstrap/Form';



function MultilanguageNavigationBar(props) {
    const { t } = useTranslation()
    const location = useLocation()
    const urlPart = location.pathname.split("/").slice(2).join("/");


/* 
    const navigate = useNavigate();
    const handleSumbit = (route) => () => {
      navigate(route, {replace: true})
    }; */
   


    useEffect(() => {
     
     
    }, [props.lang])
    return  <React.Fragment>

  <Navbar collapseOnSelect expand="lg" style={{paddingTop:"0px", paddingLeft:"15px", paddingRight: "15px", borderBottom:"1px solid #F2F2F2"}} /* bg="light" variant="light" */ >
      <Navbar.Brand href={`/${props.lang}/`}><img key={props.lang} height='40px' style={{marginTop:"1rem"}}src={`https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-${props.lang}.svg`} alt="FAO logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{paddingTop:"0px", paddingLeft:"15px", paddingRight: "15px"}} />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav className="me-auto">
            <Nav.Link style={{borderLeft:"2px solid #F2F2F2", marginTop:"1rem", fontSize:"x-large"}} href={`/${props.lang}`}>{t('alart_online').toUpperCase()}</Nav.Link>
            <Nav.Link style={{borderLeft:"2px solid #F2F2F2", marginTop:"1.5rem", marginLeft:"0.5rem", marginRight:"0.5rem"}} href={`/${props.lang}/alart/policy-issues`}>{t('revision_tool')}</Nav.Link>
            <Nav.Link style={{borderLeft:"2px solid #F2F2F2", marginTop:"1.5rem"}} href={`/${props.lang}/normative-frameworks/national/aquaculture`}>{t('normative_frameworks')}</Nav.Link>
           
            </Nav>

 <Nav style={{paddingTop:"0px", paddingLeft:"55px", paddingRight: "55px"}}>
{/*  <Form className="d-flex" style={{ paddingLeft:"5px", paddingRight: "5px"}} onSubmit={handleSumbit(`/${props.lang}/advanced-search`)}>
            <Form.Control
              type="search"
              placeholder={t('advanced_search')}
              className="me-2"
              aria-label="Search"
            />
          </Form> */}
           <NavDropdown title={props.langTitle.toUpperCase()} id="basic-nav-dropdown" style={{backgroundColor: "#e9ecef"}}>

           {languages.map(({ code, name }) => (
            <NavDropdown.Item key={code}
               href={`/${code}/${urlPart}`}
                className={classNames('dropdown-item', {
                  disabled: props.lang === code,
                })}
                onClick={() => {
                  i18next.changeLanguage(code)
                }}
              >
                {name} </NavDropdown.Item>
             
             
          ))}
            
            </NavDropdown>
           
            </Nav>
            </Navbar.Collapse>
    </Navbar>
  </React.Fragment>;
  }

  export default MultilanguageNavigationBar;